import {useParams} from "react-router";
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../../constants/routes.constants";
import {MasturbationRoutes} from "./constants";
import Correctly from "./Correctly";
import Fancy from "./Fancy";
import Intro from "./Intro";
import Sensitivity from "./Sensitivity";
import Step2 from "./Step2";
import Step4 from "./Step4";
import Step5 from "./Step5";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {PLEASURE_PATHWAYS} from "../../../CourseItem/contants";

const Masturbation = () => {
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("pleasure_pathways_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("pleasure_pathways") + 2]}
                        objectToSave={{
                            img: PLEASURE_PATHWAYS[2].img,
                            value: 'pleasure_pathways',
                            route: '/her-orgasm/1',
                            index: 2
                        }}
                        uuid={"pleasure_pathways2"}
                        route={"/courses/pleasure-pathways"}
            />
            <Routes>
                <Route path={'/1'} element={<Intro />} />
                <Route path={'/2'} element={<Correctly />} />
                <Route path={'/3'} element={<Step2 />} />
                <Route path={'/4'} element={<Step4 />} />
                <Route path={'/5'} element={<Fancy />} />
                <Route path={'/6'} element={<Sensitivity />} />
                <Route path={'/7'} element={<Step5 />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={MasturbationRoutes} routeToNavigate={COURSE_ROUTES.HER_ORGASM}  />
        </>
    )
}

export default Masturbation
import {useParams} from "react-router";
import Masterfull from "./Masterfull";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {MASTERING_YOUR_MASCULINITY} from "../../CourseItem/contants";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";

const MasterfulLove = () => {
    const { t } = useTranslation('app');
    const title = t("mastering_your_masculinity_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("MASTERING_YOUR_MASCULINITY") + 5]}
                        objectToSave={{
                            img: MASTERING_YOUR_MASCULINITY[5].img,
                            value: 'MASTERING_YOUR_MASCULINITY',
                            route: '/navigation-online/1',
                            index: 5
                        }}
                        uuid={"MASTERING_YOUR_MASCULINITY5"}
                        route={"/courses/mastering-your-masculinity"}
            />
            <Masterfull />
        </>
    )
}
export default MasterfulLove
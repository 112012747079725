import {AuthHeader} from "./screens/app/Header/Header";
import {Route, Routes, Navigate} from "react-router-dom";
import {
    COURSE_ROUTES,
    COURSES,
    GAMES_ROUTE,
    MAIN,
    PROFILE_ROUTE,
    SCENARIOS_ROUTE,
    PREMIUM_ROUTE,
    EDIT_PASSWORD_ROUTE,
    SUCCESS_ROUTE,
    EDIT_NICKNAME_ROUTE,
    PROGRESS_ROUTE,
    FAVORITES_ROUTE,
    LANGUAGES_ROUTE,
    SUCCESS_CHANGE_ROUTE, SUBSCRIPTION_ROUTE, SUBSCRIPTION_CANCELLED_ROUTE, SUBSCRIPTION_FAILED_ROUTE, SUBSCRIPTION_SUCCESS_ROUTE
} from "./constants/routes.constants";
import {Main} from "./screens/app/Main/Main";
import {Courses} from "./screens/app/Courses/Courses";
import {CourseItem} from "./screens/app/CourseItem/CourseItem";
import {Games} from "./screens/app/Games/Games";
import {GameItem} from "./screens/app/GameItem/GameItem";
import AllFirstDateRule from "./screens/app/Courses/FirstDateRule/All";
import SignOfAWorthyMan from "./screens/app/Courses/SignsOfAWorthyMan";
import ApproachesToConnecting from "./screens/app/Courses/ApproachesToConnecting";
import MaintainConversation from "./screens/app/Courses/MaintainConversation";
import PathConnection from "./screens/app/Courses/PathConnection";
import BehaveOnADate from "./screens/app/Courses/BehaveOnADate";
import Tips from "./screens/app/Courses/Tips";
import Affirmations from "./screens/app/Courses/Affirmations/Affirmations";
import AttitudeTowardsSex from "./screens/app/Courses/PathToPleasure/AttitudeTowardsSex";
import Stories from "./screens/app/Courses/Stories/Stories";
import {Scenarios} from "./screens/app/Scenarios/Scenarios";
import {ScenarioItem} from "./screens/app/Scenarios/Item";
import {Profile} from "./screens/app/Profile/Profile";
import {Footer} from "./screens/app/Footer/Footer";
import {useLocation} from "react-router";
import Masturbation from "./screens/app/Courses/PathToPleasure/Masturbation";
import Libido from "./screens/app/Courses/PathToPleasure/Libido";
import Sounds from "./screens/app/Courses/PathToPleasure/Sounds";
import Misconceptions from "./screens/app/Courses/PathToPleasure/Misconceptions";
import Preparation from "./screens/app/Courses/PathToPleasure/Preparation";
import ErogenousZones from "screens/app/Courses/PathToPleasure/ErogenousZones";
import PhrasesForMessaging from "./screens/app/Courses/PathToPleasure/PhrasesForMessaging";
import MaleErogenousZones from "./screens/app/Courses/PathToPleasure/MaleErogenousZones";
import KissingTechniques from "screens/app/Courses/PathToPleasure/KissingTechniques";
import Taboo from "screens/app/Courses/PathToPleasure/Taboo";
import SecretsTechniques from "./screens/app/Courses/PathToPleasure/SecretsTechniques";
import BlowJob from "screens/app/Courses/PathToPleasure/BlowJob";
import RidOfTheGag from "./screens/app/Courses/PathToPleasure/RidOfTheGag";
import SexToys from "screens/app/Courses/PathToPleasure/SexToys";
import SexGames from "screens/app/Courses/PathToPleasure/SexGames";
import AnalSex from "screens/app/Courses/PathToPleasure/AnalSex";
import Threesome from "./screens/app/Courses/PathToPleasure/Threesome";
import TopPoses from "./screens/app/Courses/PathToPleasure/TopPoses";
import SexScenarious from "./screens/app/Courses/PathToPleasure/SexScenarious";
import Bdsm from "screens/app/Courses/PathToPleasure/Bdsm";
import HotPhrases from "./screens/app/Courses/Emancipation/HotPhrases";
import Guest from "./screens/app/Courses/Emancipation/Guest";
import RolePlaying from "./screens/app/Courses/Emancipation/RolePlaying";
import Tests from "./screens/app/Courses/Emancipation/Tests";
import CommonTechniques from "screens/app/Courses/Emancipation/CommonTechniques";
import Poses from "./screens/app/Courses/Emancipation/Poses";
import Bottle from "./screens/app/Games/Bottle";
import TruthOrDare from "screens/app/Games/TruthOrDare";
import Sex from "./screens/app/Games/Sex";
import OralSex from "screens/app/Games/OralSex";
import HardMode from "screens/app/Games/HardMode";
import Truth from "screens/app/Games/Truth";
import Dare from "screens/app/Games/Dare";
import HotTasks from "screens/app/Games/HotTasks";
import Gentile from "screens/app/Scenarios/AtHome/Gentile";
import WebCame from "screens/app/Scenarios/AtHome/WebCame";
import HomeVideo from "screens/app/Scenarios/AtHome/HomeVideo";
import GameWithStory from "screens/app/Scenarios/AtHome/GameWithStory";
import Fantasy from "screens/app/Scenarios/AtHome/Fantasy";
import Strangers from "screens/app/Scenarios/InRestaurant/Strangers";
import Forbidden from "screens/app/Scenarios/InRestaurant/Forbidden";
import DirtyGames from "screens/app/Scenarios/InRestaurant/DirtyGames";
import PantyMethod from "screens/app/Scenarios/InRestaurant/PantyMethod";
import Fugitives from "screens/app/Scenarios/InRestaurant/Fugitives";
import ParentControl from "screens/app/Scenarios/Guest/ParentControl";
import Virgin from "screens/app/Scenarios/Guest/Virgin";
import Alien from "screens/app/Scenarios/Guest/Alien";
import CodeWord from "screens/app/Scenarios/Guest/CodeWord";
import PhoneSex from "screens/app/Scenarios/Work/PhoneSex";
import ComfortZone from "screens/app/Scenarios/Work/ComfortZone";
import Interview from "screens/app/Scenarios/Work/Interview";
import Businesswoman from "screens/app/Scenarios/Work/Businesswoman";
import PrettyWoman from "./screens/app/Scenarios/Work/PrettyWoman";
import RockStar from "./screens/app/Scenarios/Public/RockStar";
import Beach from "screens/app/Scenarios/Public/Beach";
import FirstSight from "screens/app/Scenarios/Public/FirstSight";
import Innocence from "screens/app/Scenarios/Public/Innocence";
import Sms from "./screens/app/Courses/Sms";
import Practices from "./screens/app/Courses/Practices";
import Days from "./screens/app/Courses/Days";
import { PremiumItem } from "screens/app/PremiumItem/PremiumItem";
import { Premium } from "screens/app/PremiumItem/Premium";
import { ScenariousBdsm} from "./screens/app/PremiumItem/BDSM/Scenarious";
import {Level1} from "./screens/app/PremiumItem/BDSM/Level1/Level1";
import {Level2} from "./screens/app/PremiumItem/BDSM/Level2/Level2";
import {Level3} from "./screens/app/PremiumItem/BDSM/Level3/Level3";
import { Level4 } from "screens/app/PremiumItem/BDSM/Level4";
import { Level5 } from "screens/app/PremiumItem/BDSM/Level5";
import { Level6 } from "screens/app/PremiumItem/BDSM/Level6";
import {PlayWithSperm} from "./screens/app/PremiumItem/BDSM/PlayWithSperm";
import {BlowJobPremium} from "./screens/app/PremiumItem/TruthOrDareAdvanced/BlowJob";
import {TopPositions} from "./screens/app/PremiumItem/TruthOrDareAdvanced/TopPositions";
import {Cunnilingus} from "./screens/app/PremiumItem/TruthOrDareAdvanced/Cunnilingus";
import {TruthPremium} from "./screens/app/PremiumItem/TruthOrDareAdvanced/Truth";
import {TruthHardPremium} from "./screens/app/PremiumItem/TruthOrDareAdvanced/TruthHard";
import {ManDare} from "./screens/app/PremiumItem/TruthOrDareAdvanced/ManDare";
import { ManDareHard } from "screens/app/PremiumItem/TruthOrDareAdvanced/ManDareHard";
import {GirlTruth} from "./screens/app/PremiumItem/TruthOrDareAdvanced/GirlTruth";
import { GirlTruthHard } from "screens/app/PremiumItem/TruthOrDareAdvanced/GirlTruthHard";
import {GirlDare} from "./screens/app/PremiumItem/TruthOrDareAdvanced/GirlDare";
import {GirlDareHard} from "./screens/app/PremiumItem/TruthOrDareAdvanced/GirlDareHard";
import { Monthly } from "screens/app/PremiumItem/Monthly/Monthly";
import Calendar from "./screens/app/PremiumItem/Calendar";
import EditPassword from "./screens/app/Profile/EditPassword";
import Succcess from "screens/app/Profile/Success";
import EditNickname from "screens/app/Profile/EditNickname";
import ProgressPage from "./screens/app/Progress/ProgressPage";
import FavoritesPage from "screens/app/FavoritesPage/FavoritesPage";
import {Languages} from "./screens/app/Profile/Languages";
import SuccessNickName from "./screens/app/Profile/SuccessNickName";
import {useContext, useEffect} from "react";
import {ThemeContext} from "./context/themeContext";
import MasterfulLove from "screens/app/Courses/MasterfulLove";
import TheModel from "./screens/app/Courses/FirstDateRule/TheModel";
import SuccessCancellation from "screens/app/Profile/SuccessCancellation";
import FailedSubscription from "screens/app/Profile/FailedPayment";
import Subscription from "screens/app/Profile/Subscription";
import SuccessPayment from "screens/app/Profile/SuccessPayment";
import PremiumPage from "screens/app/PremiumItem/PremiumPage";


 type TProps = {
    theme: string
}
export const AppRoutsAuth = ({theme}: TProps) => {
    const {pathname, search} = useLocation()
    const { setTheme } = useContext(ThemeContext);
    useEffect(() => {
        if(search.includes('theme=dark')){
            setTheme('dark')
        } else if(search.includes('theme=light')){
            setTheme('light')
        }
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname]);
    return (
        <div className={`appContainer app ${theme}`}>
            {['/courses', '/profile', '/premium', '/progress', '/scenarios/', '/games/', FAVORITES_ROUTE].find(i => pathname === '/' || pathname === '/main' || pathname=== '/games' || pathname=== '/scenarios' || pathname.includes(i)) && <AuthHeader/>}
            <Routes>
                <Route path={MAIN} element={<Main/>}/>
                <Route path={COURSES}>
                    <Route index element={<Courses/>}/>
                    <Route path={':id'} element={<CourseItem/>} />
                </Route>
                <Route path={GAMES_ROUTE}>
                    <Route index element={<Games/>}/>
                    <Route path={':id'} element={<GameItem/>} />
                </Route>
                <Route path={PREMIUM_ROUTE}>
                    <Route index element={<PremiumPage/>}/>
                    <Route path={':id'} element={<PremiumItem/>} />
                </Route>
                <Route path={COURSE_ROUTES.BUILDING_SELF_CONFIDENCE} element={<AllFirstDateRule />} >
                    <Route path={':tab'} element={<AllFirstDateRule />} />
                </Route>
                <Route path={COURSE_ROUTES.READING_BODY_LANGUAGE} element={<SignOfAWorthyMan />} >
                    <Route path={':tab'} element={<SignOfAWorthyMan />} />
                </Route>
                <Route path={COURSE_ROUTES.APPROACHES_TO_CONNECT} element={<ApproachesToConnecting />} >
                    <Route path={':tab'} element={<ApproachesToConnecting />} />
                </Route>
                <Route path={COURSE_ROUTES.NAVIGATION_ONLINE} element={<MaintainConversation />} >
                    <Route path={':tab'} element={<MaintainConversation />} />
                </Route>
                <Route path={COURSE_ROUTES.MAINTAINING} element={<PathConnection />} />
                <Route path={COURSE_ROUTES.PATHWAYS_TO_SUCCESS} element={<BehaveOnADate />} >
                    <Route path={':tab'} element={<BehaveOnADate />} />
                </Route>
                <Route path={COURSE_ROUTES.AROUSING_HER_INTERESTS} element={<Tips />} >
                    <Route path={':tab'} element={<Tips />} />
                </Route>
                <Route path={COURSE_ROUTES.FLIRTING_WITH_IMPACT} element={<Affirmations />} >
                    <Route path={':tab'} element={<Affirmations />} />
                </Route>
                <Route path={COURSE_ROUTES.HER_ORGASM} element={<Masturbation />} >
                    <Route path={':tab'} element={<Masturbation />} />
                </Route>
                <Route path={COURSE_ROUTES.LIBIDO} element={<Libido />} >
                    <Route path={':tab'} element={<Libido />} />
                </Route>
                <Route path={COURSE_ROUTES.MASTERING_EMOTIONAL_CONTROL} element={<Sounds />} >
                    <Route path={':tab'} element={<Sounds />} />
                </Route>
                <Route path={COURSE_ROUTES.ESCAPING} element={<Misconceptions />} >
                    <Route path={':tab'} element={<Misconceptions />} />
                </Route>
                <Route path={COURSE_ROUTES.PREPARATION} element={<Preparation />} >
                    <Route path={':tab'} element={<Preparation />} />
                </Route>
                <Route path={COURSE_ROUTES.SIZZLING} element={<ErogenousZones />} >
                    <Route path={':tab'} element={<ErogenousZones />} />
                </Route>
                <Route path={COURSE_ROUTES.GUIDE} element={<MaleErogenousZones />} >
                    <Route path={':tab'} element={<MaleErogenousZones />} />
                </Route>
                <Route path={COURSE_ROUTES.SENSUAL_TECHNIQUES} element={<KissingTechniques />} />
                <Route path={COURSE_ROUTES.PHRASES_FOR_HOT_MESSAGING} element={<PhrasesForMessaging />} >
                    <Route path={':tab'} element={<PhrasesForMessaging />} />
                </Route>
                <Route path={COURSE_ROUTES.TABOO} element={<Taboo />} />
                <Route path={COURSE_ROUTES.SECRETS_TECHNIQUES} element={<SecretsTechniques />} >
                    <Route path={':tab'} element={<SecretsTechniques />} />
                </Route>
                <Route path={COURSE_ROUTES.TURNING_HER} element={<BlowJob />} >
                    <Route path={':tab'} element={<BlowJob />} />
                </Route>
                <Route path={COURSE_ROUTES.SEX_TOYS} element={<SexToys />} >
                    <Route path={':tab'} element={<SexToys />} />
                </Route>
                <Route path={COURSE_ROUTES.SEX_GAMES} element={<SexGames />} >
                    <Route path={':tab'} element={<SexGames />} />
                </Route>
                <Route path={COURSE_ROUTES.ANAL_SEX} element={<AnalSex />} >
                    <Route path={':tab'} element={<AnalSex />} />
                </Route>
                <Route path={COURSE_ROUTES.THREESOME} element={<Threesome />} >
                    <Route path={':tab'} element={<Threesome />} />
                </Route>
                <Route path={COURSE_ROUTES.TOP_POSES} element={<TopPoses />} />
                <Route path={COURSE_ROUTES.SEX_SCENARIOS} element={<SexScenarious />} >
                    <Route path={':tab'} element={<SexScenarious />} />
                </Route>
                <Route path={COURSE_ROUTES.BDSM} element={<Bdsm />} >
                    <Route path={':tab'} element={<Bdsm />} />
                </Route>
                <Route path={COURSE_ROUTES.GUEST} element={<Guest />} >
                    <Route path={':tab'} element={<Guest />} />
                </Route>
                <Route path={COURSE_ROUTES.ROLE_PLAYING} element={<RolePlaying />} >
                    <Route path={':tab'} element={<RolePlaying />} />
                </Route>
                <Route path={COURSE_ROUTES.TESTS} element={<Tests />} >
                    <Route path={':tab'} element={<Tests />} />
                </Route>
                <Route path={COURSE_ROUTES.COMMON_TECHNIQUES} element={<CommonTechniques />} >
                    <Route path={':tab'} element={<CommonTechniques />} />
                </Route>
                <Route path={COURSE_ROUTES.POSES} element={<Poses />} >
                    <Route path={':tab'} element={<Poses />} />
                </Route>
                <Route path={COURSE_ROUTES.PRACTICES} element={<Practices />} >
                    <Route path={':tab'} element={<Practices />} />
                </Route>
                <Route path={COURSE_ROUTES.DAYS_OF_SEX_CALENDAR} element={<Calendar />} >
                    <Route path={':tab'} element={<Calendar />} />
                </Route>
                <Route path={COURSE_ROUTES.MASTERFUL_LOVE} element={<MasterfulLove />} >
                    <Route path={':tab'} element={<MasterfulLove />} />
                </Route>

                <Route path={COURSE_ROUTES.EMBRACING_CUNNILINGUS} element={<RidOfTheGag />} />
                <Route path={COURSE_ROUTES.UNLEASH_YOUR_CHARISMA} element={<TheModel />} />
                <Route path={COURSE_ROUTES.BOTTLE} element={<Bottle />} />
                <Route path={COURSE_ROUTES.TRUTH_OR_DARE} element={<TruthOrDare />} />
                <Route path={COURSE_ROUTES.SEX} element={<Sex />} />
                <Route path={COURSE_ROUTES.ORAL_SEX} element={<OralSex />} />
                <Route path={COURSE_ROUTES.HARD_MODE} element={<HardMode />} />
                <Route path={COURSE_ROUTES.TRUTH} element={<Truth />} />
                <Route path={COURSE_ROUTES.ACTION_PACKED} element={<Dare />} />
                <Route path={COURSE_ROUTES.HOT_TASKS} element={<HotTasks />} />
                <Route path={COURSE_ROUTES.GENTILE} element={<Gentile />} />
                <Route path={COURSE_ROUTES.WEBCAM_MODEL} element={<WebCame />} />
                <Route path={COURSE_ROUTES.HOME_VIDEO} element={<HomeVideo />} />
                <Route path={COURSE_ROUTES.GAME_WITH_STORY} element={<GameWithStory />} />
                <Route path={COURSE_ROUTES.FANTASY} element={<Fantasy />} />
                <Route path={COURSE_ROUTES.STRANGERS} element={<Strangers />} />
                <Route path={COURSE_ROUTES.FORBIDDEN_DEVICE} element={<Forbidden />} />
                <Route path={COURSE_ROUTES.DIRTY_GAMES} element={<DirtyGames />} />
                <Route path={COURSE_ROUTES.PANTY_METHOD} element={<PantyMethod />} />
                <Route path={COURSE_ROUTES.FUGITIVES} element={<Fugitives />} />
                <Route path={COURSE_ROUTES.PARENT_CONTROL} element={<ParentControl />} />
                <Route path={COURSE_ROUTES.VIRGIN} element={<Virgin />} />
                <Route path={COURSE_ROUTES.ALIEN_TERRITORY} element={<Alien />} />
                <Route path={COURSE_ROUTES.CODE_WORD} element={<CodeWord />} />
                <Route path={COURSE_ROUTES.PHONE_SEX} element={<PhoneSex />} />
                <Route path={COURSE_ROUTES.COMFORT_ZONE} element={<ComfortZone />} />
                <Route path={COURSE_ROUTES.INTERVIEW} element={<Interview />} />
                <Route path={COURSE_ROUTES.BUSINESSWOMAN} element={<Businesswoman />} />
                <Route path={COURSE_ROUTES.SEDUCTIVE_DEAL} element={<PrettyWoman />} />
                <Route path={COURSE_ROUTES.ROCK_STAR} element={<RockStar />} />
                <Route path={COURSE_ROUTES.BEACH} element={<Beach />} />
                <Route path={COURSE_ROUTES.FIRST_SIGHT} element={<FirstSight />} />
                <Route path={COURSE_ROUTES.INSULTED_INNOCENCE} element={<Innocence />} />
                <Route path={COURSE_ROUTES.SMS} element={<Sms />} />
                <Route path={COURSE_ROUTES.QUESTIONS} element={<AttitudeTowardsSex />} />
                <Route path={COURSE_ROUTES.HOT_PHRASES} element={<HotPhrases />} />
                <Route path={COURSE_ROUTES.THE_ART} element={<Stories />} />
                <Route path={COURSE_ROUTES.DAYS} element={<Days />} />
                <Route path={COURSE_ROUTES.SCENARIOS_BDSM} element={<ScenariousBdsm />} />
                <Route path={COURSE_ROUTES.LEVEL_1} element={<Level1 />} />
                <Route path={COURSE_ROUTES.LEVEL_2} element={<Level2 />} />
                <Route path={COURSE_ROUTES.LEVEL_3} element={<Level3 />} />
                <Route path={COURSE_ROUTES.LEVEL_4} element={<Level4 />} />
                <Route path={COURSE_ROUTES.LEVEL_5} element={<Level5 />} />
                <Route path={COURSE_ROUTES.LEVEL_6} element={<Level6 />} />
                <Route path={COURSE_ROUTES.LEVEL_7} element={<PlayWithSperm />} />
                <Route path={COURSE_ROUTES.UNFORGETTABLE_CUNNILINGUS_SCENARIOS} element={<BlowJobPremium />} />
                <Route path={COURSE_ROUTES.TOP_POSITIONS} element={<TopPositions />} />
                <Route path={COURSE_ROUTES.BLOWJOB_POSITIONS} element={<Cunnilingus />} />
                <Route path={COURSE_ROUTES.MAN_TRUTH} element={<TruthPremium />} />
                <Route path={COURSE_ROUTES.MAN_TRUTH_HARD_MODE} element={<TruthHardPremium />} />
                <Route path={COURSE_ROUTES.MAN_DARE} element={<ManDare />} />
                <Route path={COURSE_ROUTES.MAN_DARE_HARD_MODE} element={<ManDareHard />} />
                <Route path={COURSE_ROUTES.GIRL_TRUTH} element={<GirlTruth />} />
                <Route path={COURSE_ROUTES.GIRL_TRUTH_HARD_MODE} element={<GirlTruthHard />} />
                <Route path={COURSE_ROUTES.GIRL_DARE} element={<GirlDare />} />
                <Route path={COURSE_ROUTES.GIRL_DARE_HARD_MODE} element={<GirlDareHard />} />
                <Route path={COURSE_ROUTES.MONTHLY_CHALLENGES} element={<Monthly />} />
                <Route path={EDIT_PASSWORD_ROUTE} element={<EditPassword />} />
                <Route path={EDIT_NICKNAME_ROUTE} element={<EditNickname />} />
                <Route path={SUCCESS_ROUTE} element={<Succcess />} />
                <Route path={SUCCESS_CHANGE_ROUTE} element={<SuccessNickName />} />
                <Route path={PROGRESS_ROUTE} element={<ProgressPage theme={theme} />} />
                <Route path={FAVORITES_ROUTE} element={<FavoritesPage theme={theme} />} />
                <Route path={SCENARIOS_ROUTE}>
                    <Route index element={<Scenarios/>}/>
                    <Route path={':id'} element={<ScenarioItem/>} />
                </Route>
                <Route path={PROFILE_ROUTE} element={<Profile/>}/>
                <Route path={LANGUAGES_ROUTE} element={<Languages/>}/>
                <Route path={SUBSCRIPTION_ROUTE} element={<Subscription/>}/>
                <Route path={SUBSCRIPTION_CANCELLED_ROUTE} element={<SuccessCancellation/>}/>
                <Route path={SUBSCRIPTION_FAILED_ROUTE} element={<FailedSubscription isHeader={true} nextRoute={'/'}/>}/>
                <Route path={SUBSCRIPTION_SUCCESS_ROUTE} element={<SuccessPayment isHeader={true} nextRoute={'/'}/>}/>

                <Route path={'/'} element={<Main/>}/>
                {/*<Route path="*" element={<Navigate to="/" replace />} />*/}
            </Routes>
            <Footer/>
        </div>

    )
}
import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {WORKOUT_CHALLENGE} from "../../CourseItem/contants";


const Sms = () => {
    const { t } = useTranslation('app');
    const titleMain = t("workout_challenge_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("workout_challenge") + 1]}
                        objectToSave={{
                            img: WORKOUT_CHALLENGE[1].img,
                            value: 'workout_challenge',
                            route: '/days',
                            index: 1
                        }}
                        uuid={"workout_challenge1"}
                        route={"/courses/workout-challenge"}
            />
        <div className="child-container">
            <div className="child-container__article-title typography-16">
                {t("game1_days_days_article")}
            </div>
            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-20">
                {t("game1_days_subtitle1")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("game1_days_list1", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-20">
                {t("game1_days_subtitle2")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("game1_days_list2", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas font-bebas mt-24 typography-20">
                {t("game1_days_subtitle3")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy"  src={imgUrl[2]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("game1_days_list3", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
        </div>
            </>
    )
}

export default Sms
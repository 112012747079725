import './styles.scss'
import {ReactComponent as StarIcon} from "assets/app/start.svg";
import {ReactComponent as ThemeIcon} from "assets/app/Profile/theme.svg";
import {ReactComponent as LanguageIcon} from "assets/app/Profile/language.svg";
import {ReactComponent as TermsIcon} from "assets/app/Profile/terms.svg";
import {ReactComponent as PolicyIcon} from "assets/app/Profile/policy.svg";
import {ReactComponent as Subscription} from "assets/app/Profile/subscription.svg";
import {ReactComponent as EditIcon} from "assets/app/edit.svg";
import {ReactComponent as LogOutIcon} from "assets/app/Profile/LogOut.svg";
import {AppTheme} from "../../../components/AppTheme/AppTheme";
import {useNavigate} from "react-router";
import {EDIT_NICKNAME_ROUTE, EDIT_PASSWORD_ROUTE, FAVORITES_ROUTE, LANGUAGES_ROUTE, SUBSCRIPTION_ROUTE} from 'constants/routes.constants';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {getUserData, logOutRequest} from "../../../utils/firebase/userData.utils";
import {getAuth} from "firebase/auth";
export const  Profile = () => {
    const navigate = useNavigate();
    const { t } = useTranslation('app');
    const termsConditions = t('terms_and_conditiions')
    const privacyPolicy = t('privacy_policy')
    const subscription = t('subscription')
    const logOut = t('log_out')
    const profile = t('profile')
    const nick = t('nick')
    const email = t('email')
    const favorites = t('favorites')
    const language = t('language')
    const password = t('password')
    const general = t('general')
    const settings = t('settings')
    const theme = t('theme')
    const user = getAuth();
    const [userData, setUserData] = useState<{username: string, userEmail: string}>({username: '', userEmail: ""})
    const getUser = async () => {
        const res =  await getUserData(user?.currentUser?.uid)
        setUserData(res)
    }
    const handleLogOut = async () => {
        await logOutRequest(user?.currentUser?.uid, () => {
            localStorage.removeItem("accessToken")
            navigate('/')
        })
    }

    useEffect(() =>{
        getUser()
    }, [user])

    return(
        <div className="profile-container app-wrapper">
            <div className="profile-container__title typography-28 mt-32 justify-center font-bebas">
                {profile}
            </div>
            <div className="profile-container__block general mt-24">
                <div className="profile-container__block__title typography-20 typography-500">
                    {general}
                </div>
                <div className="profile-container__block__row-wrapper row-container space-between base-line mt-16">
                    <div className="row-container base-line">
                    <div className="profile-container__block__row-wrapper__title typography-16 typography-500 mr-12">
                        {nick}
                    </div>
                    <div className="profile-container__block__row-wrapper__title typography-16">
                        {userData?.username || ''}
                    </div>
                    </div>
                    <div className="profile-container__block__row-wrapper__btn typography-20 typography-400" onClick={() => navigate(EDIT_NICKNAME_ROUTE)}>
                  <EditIcon />
                    </div>
                </div>
                <div className="profile-container__block__row-wrapper row-container base-line">
                    <div className="profile-container__block__row-wrapper__title typography-16 mr-12 typography-500">
                        {email}
                    </div>
                    <div className="profile-container__block__row-wrapper__title typography-16">
                        {userData?.userEmail || ''}
                    </div>
                </div>
                <div className="profile-container__block__row-wrapper row-container space-between base-line">
                    <div className="profile-container__block__row-wrapper__title typography-16 typography-500">
                        {password}
                    </div>
                    <div className="profile-container__block__row-wrapper__btn typography-20 typography-400" onClick={() => navigate(EDIT_PASSWORD_ROUTE)}>
                      <EditIcon />
                    </div>
                </div>
            </div>
            <div className="profile-container__block general mt-24 row-container align-center">
                <div className="profile-container__block__icon mr-12">
                    <StarIcon />
                </div>
                <div className="profile-container__block__title typography-16 typography-500" onClick={() => navigate(FAVORITES_ROUTE)}>
                    {favorites}
                </div>
            </div>
            <div className="profile-container__block__main-title typography-20 typography-500 mt-24">
                {settings}
            </div>
            <div className="profile-container__block general mt-24">
                <div className="row-container profile-container__block__settings space-between">
                    <div className="row-container">
                        <ThemeIcon width={20} height={20} className="mr-12" />
                        <div className="typography-16 typography-500">
                            {theme}
                        </div>
                    </div>
                    <AppTheme />
                </div>
                <div className="profile-container__block__settings row-container space-between align-center" onClick={() => navigate(LANGUAGES_ROUTE)}>
                    <div className="row-container">
                        <LanguageIcon width={20} height={20} className="mr-12" />
                        <div className="typography-16 typography-500">
                            {language}
                        </div>
                    </div>
                    <div className="profile-container__block__row-wrapper__btn typography-20 typography-400">
                 <EditIcon />
                    </div>
                </div>
                <div className="profile-container__block__settings row-container align-center">
                    <div className="row-container">
                        <TermsIcon width={20} height={20} className="mr-12" />
                        <div className="typography-16 typography-500"
                             onClick={() => window.open('https://manmastery.app/terms-and-conditions/')}
                        >
                            {termsConditions}
                        </div>
                    </div>
                </div>
                <div className="profile-container__block__settings row-container align-center">
                    <div className="row-container">
                        <PolicyIcon width={20} height={20} className="mr-12" />
                        <div className="typography-16 typography-500"
                             onClick={() => window.open('https://manmastery.app/privacy-policy/')}
                        >
                            {privacyPolicy}
                        </div>
                    </div>
                </div>
                <div className="profile-container__block__settings row-container align-center">
                    <div className="row-container">
                        <Subscription width={20} height={20} className="mr-12" />
                        <div className="typography-16 typography-500" onClick={() => navigate(SUBSCRIPTION_ROUTE)}>
                            {subscription}
                        </div>
                    </div>
                </div>
                <div className="profile-container__block__settings log-out row-container align-center" onClick={handleLogOut}>
                    <div className="row-container">
                        <LogOutIcon width={20} height={20} className="mr-12" />
                        <div className="profile-container__block__settings__red-text typography-16 typography-500">
                            {logOut}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
import {useParams} from "react-router";
import '../../FirstDateRule/styles.scss'
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../../constants/routes.constants";
import {routes} from "./constants";
import {Part2} from "./Part2";
import {AccordingToMen} from "./AccordingToMen";
import {Lazy} from "./Lazy";
import {Advanced} from "./Advanced";
import {Pregnant} from "./Pregnant";
import Intro from "./Intro";
import {Car} from "./Car";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import { getAuth } from "firebase/auth";
import { useState, useEffect } from "react";
import { EROTIC_MASTERY } from "screens/app/CourseItem/contants";
import { TFavoritesList } from "screens/app/FavoritesPage/FavoritesPage";
import { getListOfFavorites } from "utils/firebase/userData.utils";
const Poses = () =>{
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const titleMain = t("erotic_mastery_course")?.split(' ')[0]
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("erotic_mastery") + 5]}
                        objectToSave={{
                            img: EROTIC_MASTERY[5].img,
                            value: 'erotic_mastery',
                            route: '/poses/1',
                            index: 5
                        }}
                        uuid={"erotic_mastery5"}
                        route={"/courses/erotic_mastery"}
            />
            <Routes>
                <Route path={'/1'} element={<Intro />} />
                <Route path={'/2'} element={<Part2 />} />
                <Route path={'/3'} element={<AccordingToMen />} />
                <Route path={'/4'} element={<Lazy />} />
                <Route path={'/5'} element={<Advanced />} />
                <Route path={'/6'} element={<Pregnant />} />
                <Route path={'/7'} element={<Car />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={routes} routeToNavigate={COURSE_ROUTES.POSES}  />
        </>
    )
}

export default Poses
import {useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './LogInScreen.scss';
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {useEffect, useState} from "react";
import {Input} from "../../../components/lego/Input/Input";
import {Button} from "../../../components/lego/Button/Button";
import {useLocation, useNavigate} from "react-router";
import {isEmailValid, isEmptyInput} from "../../../utils/login.utils";
import {useLogInErrorLogic} from "../../../hooks/onboarding/useLogInErrorLogic";
import {FormErrorMessages, LogInWarnings} from "../../../constants/logInErrorMessages.constants";
import {logInUserAccount} from "../../../utils/firebase/auth/logInUser.utils";
import {Footer} from "../../../components/lego/Footer/Footer";
import {Link} from "react-router-dom";
import {FORGOT_PASSWORD_ROUTE, LOG_IN_ROUTE, START_QUIZ_ROUTE} from "../../../constants/routes.constants";
import {googleLogo, logInLogo} from "../../../assets/onBoardingImages/logInLogo";
import {loginWithGoogle} from "../../../utils/firebase/auth/googleAuth.utils";
import {PolicyAnchors} from "../../../constants/onBoardingConstants/policyAnchors.constants";

const EMAIL_ID='email'
const PASSWORD_ID='password'
export const LogInScreen =({nextRoute}:{nextRoute:string})=> {
    const {t} = useTranslation('onboarding');
    const navigate = useNavigate()
    const location = useLocation()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const {errorState, setErrorState, clearErrorMessage} = useLogInErrorLogic()
    const onLogInFormSend = () => {
        if (!isEmptyInput(email)) {
            setErrorState({message: t(FormErrorMessages.EMPTY_EMAIL), errorInputId: EMAIL_ID})
            return;
        }

        if (!isEmptyInput(password)) {
            setErrorState({message: t(FormErrorMessages.EMPTY_PASSWORD), errorInputId: PASSWORD_ID})
            return;
        }

        if (!isEmailValid(email)) {
            setErrorState({message: t(FormErrorMessages.NOT_VALID_EMAIL), errorInputId: EMAIL_ID})
            return;
        }

        if (password.length <= 6) {
            setErrorState({message: t(FormErrorMessages.SHORT_PASSWORD), errorInputId: PASSWORD_ID})
            return;
        }

        logInUserAccount({
            email, password,
            successfulAuthRoute: () => navigate(nextRoute),
            newAccountRoute: () =>
                navigate(LOG_IN_ROUTE,
                    {replace: true, state: {warningFlag: LogInWarnings.DO_NOT_HAVE_ACCOUNT}}),
            confirmEmailRoute: () =>
                navigate(LOG_IN_ROUTE,
                    {replace: true, state: {warningFlag: LogInWarnings.DO_NOT_CONFIRM_EMAIL}}),
            googleAuth: () => setErrorState({message: 'Please log in with your Google account below', errorInputId: EMAIL_ID})
        })
    }

        const clearErrorData = (value: string, setInputValue: (inputValue: string) => void) => {
            clearErrorMessage()
            setInputValue(value)
        }

        useEffect(()=>{
            if(location.state && location.state.warningFlag) {
                location.state.warningFlag === LogInWarnings.DO_NOT_HAVE_ACCOUNT ?
                    setErrorState({message: t('logInScreen.doNotHaveAccountWarning'), errorInputId: EMAIL_ID})
                    : setErrorState({message: t("logInScreen.emailNotConfirmedWarning"), errorInputId: EMAIL_ID})
            }},[location])

        return (
            <>
                <ContentContainer>
                    <div>
                        <h4 className={'logInTitle'}>{t("login")} <img loading="lazy" src={logInLogo} alt={''}/></h4>
                    </div>
                    <div className={'logInForm'}>
                        <Input onChange={(value) => clearErrorData(value, setEmail)}
                               id={EMAIL_ID}
                               inputType={'email'}
                               labelText={t("logInScreen.email.label")}
                               placeholderText={''}
                               errorMessage={errorState.errorInputId === EMAIL_ID ? errorState.message : ''}
                        />

                        <Input onChange={(value) => clearErrorData(value, setPassword)}
                               id={PASSWORD_ID}
                               inputType={'password'}
                               labelText={t("logInScreen.password.label")}
                               placeholderText={''}
                               errorMessage={errorState.errorInputId === PASSWORD_ID ? errorState.message : ''}
                        />
                    </div>

                    <div className={'logInButtons'}>
                        <Button onClick={onLogInFormSend} text={t("logInScreen.logInButtonText")}
                                buttonTheme={ButtonThemes.DARK} width={100}
                        />
                        <Button onClick={() => loginWithGoogle({
                                successfulAuthRoute: () => navigate(nextRoute),
                                isLogInScreen: true,
                                newAccountRoute: () =>
                                    navigate(LOG_IN_ROUTE,
                                        {replace: true, state: {warningFlag: LogInWarnings.DO_NOT_HAVE_ACCOUNT}}),
                                confirmEmailRoute: () =>
                                    navigate(LOG_IN_ROUTE,
                                        {replace: true, state: {warningFlag: LogInWarnings.DO_NOT_CONFIRM_EMAIL}})
                            },
                        )}
                                text={t("createAccountScreen.googleSignInText")}
                                buttonTheme={ButtonThemes.LIGHT} width={100} buttonLogo={googleLogo}
                        />
                    </div>
                    <p className={'doNotHaveAccount'}>{t("logInScreen.noAccountText")}
                        <Link to={START_QUIZ_ROUTE}>
                            {` ${t("logInScreen.startQuizButtonText")}`}
                        </Link>
                    </p>
                    <p className={'doNotHaveAccount'}>
                        <Link to={FORGOT_PASSWORD_ROUTE}>
                            {` ${t("logInScreen.forgotPassword")}`}
                        </Link>
                    </p>
                </ContentContainer>

                <Footer text={<span className="">
                    {t("privatePolicyText")}
                    <a href={PolicyAnchors.TERMS}>{t("createAccountScreen.policyAnchors.terms")}</a>      &nbsp;
                    <a href={PolicyAnchors.PRIVACY}>{t("createAccountScreen.policyAnchors.privacy")}</a>{' '}      &nbsp;
                    <a href={PolicyAnchors.SUBSCRIPTION}>{t("createAccountScreen.policyAnchors.subscription")}</a>      &nbsp;
                </span>}/>
            </>
        )
    }


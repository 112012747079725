import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {IN_RESTAURANT} from "../../constants";


const PantyMethod = () => {
    const { t } = useTranslation('app');
    const article = t("scenarios2_panty_article1")
    const article1 = t("scenarios2_panty_article2")
    const article2 = t("scenarios2_panty_article3")
    const article3 = t("scenarios2_panty_article4")
    const subtitle = t("scenarios2_panty_subtitle")
    const subtitle1 = t("scenarios2_panty_subtitle2")
    const list1 = t("scenarios2_panty_list", { returnObjects: true })
    const list2 = t("scenarios2_panty_list1", { returnObjects: true })
    const titleMain = t("in_restaurant_scenario")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("in_restaurant") + 3]}
                        objectToSave={{
                            img: IN_RESTAURANT[3].img,
                            value: 'in_restaurant',
                            route: '/panty-method',
                            index: 3
                        }}
                        uuid={"in_restaurant3"}
                        route={"/scenarios/in-restaurant"}
            />
        <div className="child-container">
            <div className="child-container__article-title typography-16">
                {article}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {article1}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title typography-16">
                {article2}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>

            <div className="child-container__list">
                {list2.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[2]} alt="course-preview" />
            </div>
            <div className="child-container__article-title typography-16">
                {article3}
            </div>
        </div>
            </>
    )
}

export default PantyMethod
import React, {RefObject, useMemo, useState} from "react";
import {Input} from "../../lego/Input/Input";
import {useTranslation} from "react-i18next";
import {useLogInErrorLogic} from "../../../hooks/onboarding/useLogInErrorLogic";

const CARD_NUMBER ='card_number';
const EXPIRED_DATE ='EXPIRED_DATE';
const CARD_NAME ='CARD_NAME';
const CVV ='CVV';

type TProps = {
    cardNumber: string
    cvv: string
    expDate: string
    cardName: string
    setCardNumber: (val: string)=> void
    setCVV: (val: string)=> void
    setExpDate: (val: string)=> void
    setCardName: (val: string)=> void
    wrongCard: boolean
}

const CardInfo = ({wrongCard, cardNumber, cvv, setCVV, setCardNumber, expDate, setExpDate, cardName, setCardName}: TProps) => {
    const {t} = useTranslation('app')

    const {errorState, setErrorState, clearErrorMessage}=useLogInErrorLogic()
    const clearErrorData=(value:string, setInputValue: (inputValue:string)=>void)=>{
        clearErrorMessage()
        setInputValue(value)
    }

    return(
        <div className="paymentForm">
            <Input onChange={(value) => {
                let newValue = value.replace(/\D/g, ''); // Remove non-numeric characters
                // Add space every 4 characters
                newValue = newValue.replace(/(\d{4})(?=\d)/g, '$1 ');

                clearErrorData(newValue, setCardNumber)
            }}
                   id={CARD_NUMBER}
                   inputType={'card_number'}
                   placeholderText={"xxxx xxxx xxxx xxxx"}
                   maxLength={19}
                   value={cardNumber}
                   errorMessage={errorState.errorInputId === CARD_NUMBER ? errorState.message : ''}
            />
            {wrongCard && <p className="inputErrorMessage">{t("card_not_supported")}</p>}
            <div style={{marginTop: 24}}/>
            <Input onChange={(value) => clearErrorData(value, setCardName)}
                   id={CARD_NAME}
                   inputType={'card_name'}
                   value={cardName}
                   placeholderText={t("first_name") + '/' + t("last_name")}
                   errorMessage={errorState.errorInputId === CARD_NAME ? errorState.message : ''}
            />
            <div className="row-container space-between" style={{gap: 16}}>
                <Input onChange={(value) => {
                    let newValue = value.replace(/\D/g, ''); // Remove non-numeric characters
                    if (newValue.length >= 2) {
                        newValue = newValue.replace(/(\d{2})(.*)/, '$1/$2');
                    }
                    clearErrorData(newValue, setExpDate)
                }}
                       id={EXPIRED_DATE}
                       inputType={'expired_date'}
                       placeholderText={"MM/YY"}
                       maxLength={5}
                       value={expDate}
                       errorMessage={errorState.errorInputId === EXPIRED_DATE ? errorState.message : ''}
                />
                <Input onChange={(value) => clearErrorData(value, setCVV)}
                       id={CVV}
                       inputType={'cvv'}
                       placeholderText={"CVV"}
                       maxLength={4}
                       value={cvv}
                       errorMessage={errorState.errorInputId === CVV ? errorState.message : ''}
                />
            </div>
        </div>
    )
}

export default CardInfo
import {useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './RelationshipIssuesScreen.scss';
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";
import { MIXPANEL_API } from "components/onboarding/PayInfoContainer/constants";
const answers = [
    "My partner can be a handful. Having a straightforward conversation with her is a challenge.",
    "Women these days often don't share my values and life goals. We're on different paths.",
    "Handling some domestic issues that require my attention and expertise.",
    "The bedroom has lost its spark. Time to rekindle the fire, but she's not up for trying new things, and it feels like forever since I had an exciting night out.",
    "She's been giving other guys too much attention. It's time to step up and remind her who's in control."
]

export const RelationshipIssuesScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');

    const relationshipIssuesOptions:string[]= [
        t("relationshipIssuesScreen.noCommonInterestsIssueText"),
        t("relationshipIssuesScreen.understandingIssueText"),
        t("relationshipIssuesScreen.domesticIssueText"),
        t("relationshipIssuesScreen.boredomIssueText"),
        t("relationshipIssuesScreen.interestIssueText"),];
    const trackMixpanel = async(option: number) => {
        const token = localStorage.getItem("accessToken")
        const fbclid = localStorage.getItem("fbclid")
        const subid = localStorage.getItem("subid")
        const email = localStorage.getItem("email")
        const clientId = localStorage.getItem("clientId")
        const data = {
            event: 'onboarding_question_responded',
            data: {
                onboarding_step_number: 4,
                question: 'What\'s on your mind when it comes to your current situation with women?',
                respond: answers[option],
            },
            fbclid: fbclid,
            subid: subid,
            clientId: clientId,
            email: email
        }
        await fetch(MIXPANEL_API, {method: "POST", headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": "application/json"
            }, body: JSON.stringify(data)})
    }
    return (
        <>
            <ContentContainer>
                <h6 className={'relationshipIssueQuestion font-bebas typography-20 typography-400'}>{t("relationshipIssuesScreen.question")}</h6>
            </ContentContainer>
            <div>
                {relationshipIssuesOptions.map((optionText, idx)=>{
                  return <NextRouteButton key={optionText} option={optionText}
                                          onClick={() => trackMixpanel(idx)}
                                     route={nextRoute}
                                     buttonTheme={ButtonThemes.DARK}
                                     className={'relationshipIssueOptionButton'} />
                })}
            </div>
        </>
    )
}

import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {BDSM_GAME} from "../../contants";

export const PlayWithSperm = () => {
    const { t } = useTranslation('app');
    const list = t("premium_bdsm8_list", { returnObjects: true })
    const titleMain = t("bdsm_game_premium")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("bdsm_game") + 7]}
                        objectToSave={{
                            img: BDSM_GAME[7].img,
                            value: 'bdsm_game',
                            route: '/level-7',
                            index: 7
                        }}
                        uuid={"bdsm_game7"}
                        route={"/premium/bdsm-game"}
            />
        <div className="child-container">
            {list.map((i: string, idx: number) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                    {idx === 7 && <div className="child-container__image mt-24">
                        <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
                    </div>}
                </>
            })
            }

            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>

        </div>
            </>
            )
}
import '../FirstDateRule/styles.scss'
import {useParams} from "react-router";
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../constants/routes.constants";
import {TipsRoutes} from "./constants";
import Intrigue from "./Intrigue";
import Origami from './Origami';
import Escape from './Escape';
import Lingerie from './Lingerie';
import Soothsayer from './Soothsayer';
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {MASTERING_YOUR_MASCULINITY} from "../../CourseItem/contants";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
const Tips  = () => {
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("mastering_your_masculinity_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("MASTERING_YOUR_MASCULINITY") + 8]}
                        objectToSave={{
                            img: MASTERING_YOUR_MASCULINITY[8].img,
                            value: 'MASTERING_YOUR_MASCULINITY',
                            route: '/arousing-her-interests/1',
                            index: 8
                        }}
                        uuid={"MASTERING_YOUR_MASCULINITY8"}
                        route={"/courses/mastering-your-masculinity"}
            />
            <Routes>
                <Route path={'/1'} element={<Intrigue />} />
                <Route path={'/2'} element={<Origami />} />
                <Route path={'/3'} element={<Escape />} />
                <Route path={'/4'} element={<Lingerie />} />
                <Route path={'/5'} element={<Soothsayer />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={TipsRoutes} routeToNavigate={COURSE_ROUTES.AROUSING_HER_INTERESTS}  />
        </>
    )
}

export default Tips
import {useParams} from "react-router";
import '../../FirstDateRule/styles.scss'
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../../constants/routes.constants";
import {routes} from "./constants";
import {AtHome} from "./AtHome";
import Intro from "./Intro";
import {AtHome2} from "./AtHome2";
import {AtHome3} from "./AtHome3";
import {AtHome4} from "./AtHome4";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {EROTIC_MASTERY} from "../../../CourseItem/contants";
const Guest = () =>{
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const titleMain = t("erotic_mastery_course")?.split(' ')[0]
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("erotic_mastery") + 1]}
                        objectToSave={{
                            img: EROTIC_MASTERY[1].img,
                            value: 'erotic_mastery',
                            route: '/guest/1',
                            index: 1
                        }}
                        uuid={"erotic_mastery1"}
                        route={"/courses/erotic_mastery"}
            />
            <Routes>
                <Route path={'/1'} element={<Intro />} />
                <Route path={'/2'} element={<AtHome />} />
                <Route path={'/3'} element={<AtHome2 />} />
                <Route path={'/4'} element={<AtHome3 />} />
                <Route path={'/5'} element={<AtHome4 />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={routes} routeToNavigate={COURSE_ROUTES.GUEST}  />
        </>
    )
}

export default Guest
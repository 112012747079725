import {ReactComponent as CheckIcon} from "assets/app/check.svg";
import {ReactComponent as LogoIcon} from "assets/app/logo.svg";
import {useNavigate} from "react-router";
import '../../../components/TabsHeader/style.scss'
import {useTranslation} from "react-i18next";
import {Button} from "../../../components/lego/Button/Button";
import {ButtonThemes} from "../../../constants/button.constants";
import './styles.scss';

type TProps = {isHeader: boolean, nextRoute: string}


const SuccessPayment = (props: TProps) => {
    const navigate = useNavigate()
    const { t } = useTranslation('app');

    const onSend=()=>{
        navigate(!!props.nextRoute ? props.nextRoute: '/')
    }

    return (
        <div  className="change-password-wrapper success-page">
            {props?.isHeader && <div className="success-container__header">
                <LogoIcon/>
            </div>}
            <div className="success-container">
                <div className="success-container__icon">
                    <CheckIcon />
                </div>
                <div className="success-container__title typography-32 typography-500">
                    {t("successTitle")}
                </div>
                <div className="success-container__subtitle align-center typography-14">
                    {t("your_payment_has_been_successfully")}
                </div>
            </div>
            <Button className="btn-change-password" onClick={onSend} text={t("back_to_content")} buttonTheme={ButtonThemes.DARK} width={100}/>
        </div>
    )
}

export default SuccessPayment
import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {GUEST} from "../../constants";


const ParentControl = () => {
    const { t } = useTranslation('app');
    const article = t("scenarios3_parent_control_article1")
    const article1 = t("scenarios3_parent_control_article2")

    const list1 = t("scenarios3_parent_control_list", { returnObjects: true })
    const list2 = t("scenarios3_parent_control_list1", { returnObjects: true })
    const titleMain = t("guest_scenario")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("guest") + 0]}
                        objectToSave={{
                            img: GUEST[0].img,
                            value: 'guest',
                            route: '/parent-control',
                            index: 0
                        }}
                        uuid={"guest0"}
                        route={"/scenarios/guest"}
            />
            <div className="child-container">

            <div className="child-container__article-title typography-16">
                {article}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
                <div className="child-container__list">
                    {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
                </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list2.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[2]} alt="course-preview" />
            </div>
                <div className="child-container__article-title mt-16 typography-16">
                    {article1}
                </div>
        </div>
            </>
    )
}

export default ParentControl
import {Premium} from "./Premium";
import './styles.scss'
import {Button} from "../../../components/lego/Button/Button";
import {ButtonThemes} from "../../../constants/button.constants";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Payment} from "./Payment";
import { GET_SUBSCRIPTION_PREMIUM} from "../../../components/onboarding/PayInfoContainer/constants";
import {getAuth} from "firebase/auth";

const PremiumPage = () => {
    const user = getAuth()
    const { t } = useTranslation('app');
    const [open, setIsOpen] = useState(false)
    const [hasSubscription, setHasSubscription] = useState(null)
    const onSend = () =>setIsOpen(true)

    const checkIfSubscription = async () => {
        if(!user?.currentUser?.accessToken) return
        const res = await fetch(GET_SUBSCRIPTION_PREMIUM, {method: "GET",  credentials: 'include', headers: {Authorization: 'Bearer ' + user?.currentUser?.accessToken, "Access-Control-Allow-Origin": "*"}})
            .then((response) => response.json())
        setHasSubscription(res)
    }

    useEffect(() => {
        checkIfSubscription()
    }, [user]);

    return(
        <>{hasSubscription ? <Premium /> :
            <div className="premium-empty-content column align-center space-between mt-32">
                <div className="premium-empty-content__title typography-28">
                    Premium
                </div>
                <div className="premium-empty-content__subtitle typography-18 mt-24">
                    {t("premium_title")}
                </div>
                <div className="premium-empty-content__text mt-12 typography-14">
                    {t("premium_subtitle")}
                </div>
                <Button className="btn-change-password" onClick={onSend} text={t("premium_skip")} buttonTheme={ButtonThemes.DARK} width={100}/>
                <Payment
                    t={t}
                    open={open}
                    setOpen={setIsOpen}
                />
            </div>}
            </>
    )
}

export default PremiumPage
import './FlagDropdown.scss';
import React, {useContext, useEffect, useState} from "react";
import Select, {StylesConfig} from "react-select";
import {ILanguage, languages} from "../../constants/language.constants";
import {i18n} from "../../i18n";
import {ThemeContext} from "../../context/themeContext";
import {
    AppThemes, DARK_PRIMARY_RED_COLOR_HOVER,
    DARK_THEME,
    LIGHT_THEME,
    PRIMARY_RED_COLOR,
    PRIMARY_RED_COLOR_HOVER
} from "../../constants/theme.constants";
import {getLanguageFromStorage, setLanguageToStorage} from "../../utils/localStorage/language.utils";
import {MIXPANEL_API} from "../onboarding/PayInfoContainer/constants";

export const defaultLanguage:ILanguage = languages[0];

export const colourStyles: StylesConfig<ILanguage>={
        control: (styles) => ({...styles, border: 0, boxShadow: 'none', minWidth:'max-content'}),
        input: (styles) => ({...styles, caretColor: 'transparent'}),
        valueContainer: (styles) => ({...styles, padding: 0}),
        dropdownIndicator: (styles) => ({...styles, padding: '0 5px 0 0',}),
        indicatorsContainer: (styles) => ({...styles, padding: 0}),
        singleValue: (styles) => ({...styles, margin: 0}),
        menu: (styles) => ({...styles, margin: '0 0 0 -5px',}),
};

export const getInitialLanguageFromStorage=():ILanguage|undefined=>{
    return languages.find((language)=>language.value===getLanguageFromStorage())
}

export const FlagDropdown =()=>{
    const [selectedLanguage, setSelectedLanguage] =
        useState<ILanguage>(getInitialLanguageFromStorage()||defaultLanguage);
    const { theme } = useContext(ThemeContext);
    const trackMixpanel = async(language: any) => {
        const token = localStorage.getItem("accessToken")
        const fbclid = localStorage.getItem("fbclid")
        const subid = localStorage.getItem("subid")
        const email = localStorage.getItem("email")
        const clientId = localStorage.getItem("clientId")
        const data = {
            event: 'language_change',
            data:{
                property: language.value,
            },
            fbclid:fbclid,
            subid:subid,
            clientId:clientId,
            email: email
        }
        await fetch(MIXPANEL_API, {method: "POST", headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": "application/json"
            }, body: JSON.stringify(data)})
    }
    const onLanguageChange=(language:any)=>{
        i18n.changeLanguage(language.value)
        setLanguageToStorage(language.value)
        setSelectedLanguage(language)
        trackMixpanel(language)
    }

    useEffect(()=>{
        setSelectedLanguage(getInitialLanguageFromStorage()||defaultLanguage)

        const lngFromStorage=getInitialLanguageFromStorage()?.value;
        i18n.changeLanguage(lngFromStorage||defaultLanguage.value)

        return ()=>setLanguageToStorage(selectedLanguage.value)
    },[])

    return (
            <Select
                styles={colourStyles}
                value={selectedLanguage}
                onChange={onLanguageChange}
                options={languages}
                components={{IndicatorSeparator: ()=> null}}
                formatOptionLabel={(language)=><img loading="lazy" alt={''} src={language.flagImage} className='flagIcon'/>}
                theme={(themeView) => ({
                    ...themeView,
                    colors: {
                        ...themeView.colors,
                        primary25: theme===AppThemes.DARK?DARK_PRIMARY_RED_COLOR_HOVER:PRIMARY_RED_COLOR_HOVER,
                        primary50: theme===AppThemes.DARK?DARK_PRIMARY_RED_COLOR_HOVER:PRIMARY_RED_COLOR_HOVER,
                        primary: PRIMARY_RED_COLOR,
                        neutral0: theme===AppThemes.DARK? DARK_THEME: LIGHT_THEME,
                        neutral20:theme===AppThemes.DARK? LIGHT_THEME: PRIMARY_RED_COLOR
                    },
                })}
            />
    );
}

import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {WORKOUT_CHALLENGE} from "../../CourseItem/contants";
import {BONDING_ADVENTURES_COUPLES} from "../../GameItem/contants";


const TruthOrDare = () => {
    const { t } = useTranslation('app');
    const title = t("games2_truth_days_title")
    const subtitle = t("games2_truth_subtitle1")
    const subtitle1 = t("games2_truth_subtitle2")
    const subtitle2 = t("games2_truth_subtitle3")
    const subtitle4 = t("games2_truth_subtitle4")
    const subtitle5 = t("games2_truth_subtitle5")
    const subtitle6 = t("games2_truth_subtitle6")
    const note = t("games2_truth_note1")
    const list = t("games2_truth_list", { returnObjects: true })
    const list1 = t("games2_truth_list1", { returnObjects: true })
    const list2 = t("games2_truth_list2", { returnObjects: true })
    const list3 = t("games2_truth_list3", { returnObjects: true })
    const list4 = t("games2_truth_list4", { returnObjects: true })
    const list5 = t("games2_truth_list5", { returnObjects: true })
    const list6 = t("games2_truth_list6", { returnObjects: true })
    const titleMain = t("bonding_adventures_couples_game")

    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("bonding_adventures_couples") + 1]}
                        objectToSave={{
                            img: BONDING_ADVENTURES_COUPLES[1].img,
                            value: 'bonding_adventures_couples',
                            route: '/truth-or-dare',
                            index: 1
                        }}
                        uuid={"bonding_adventures_couples1"}
                        route={"/games/bonding-adventures-couples"}
            />
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle2}
            </div>
            <div className="child-container__list">
                <ul>
                    {list2.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle4}
            </div>
            <div className="child-container__list">
                <ul>
                    {list3.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note}
                </div>
            </div>
            <div className="child-container__list">
                {list4.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[2]} alt="course-preview" />
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle5}
            </div>
            <div className="child-container__list">
                <ul>
                    {list5.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[3]} alt="course-preview" />
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {subtitle6}
            </div>
            <div className="child-container__list">
                {list6.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[4]} alt="course-preview" />
            </div>
        </div>
            </>
    )
}

export default TruthOrDare
import '../../FirstDateRule/styles.scss'
import {useTranslation} from "react-i18next";
import {images1} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {PLEASURE_PATHWAYS} from "../../../CourseItem/contants";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
const AttitudeTowardsSex = () => {
    const { t } = useTranslation('app');
    const list = t("questions_to_ask_list1", { returnObjects: true })
    const titleMain = t("pleasure_pathways_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("pleasure_pathways") + 1]}
                        objectToSave={{
                            img: PLEASURE_PATHWAYS[1].img,
                            value: '/pleasure-pathways',
                            route: '/questions',
                            index: 1
                        }}
                        uuid={"pleasure_pathways1"}
                        route={"/courses/pleasure-pathways"}
            />
        <div className="child-container">
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[0]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("questions_to_ask_list2", { returnObjects: true }).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[1]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("questions_to_ask_list3", { returnObjects: true }).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[2]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {t("questions_to_ask_list4", { returnObjects: true }).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={images1[3]} alt="course-preview" />
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {t("questions_to_ask_note")}
                </div>
            </div>
        </div>
            </>
    )
}

export default AttitudeTowardsSex
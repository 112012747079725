import './AgeCheck.scss';
import {useTranslation} from "react-i18next";
import {AGE_GAPS} from "../../../constants/onBoardingConstants/ageCheck.constants";
import {ButtonThemes} from "../../../constants/button.constants";
import {ContentContainer} from "../ContentContainer/ContentContainer";
import {NextRouteButton} from "../../NextRouteButton/NextRouteButton";
import {MIXPANEL_API} from "../PayInfoContainer/constants";

export const AgeCheck =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');
    const trackMixpanel = async(option: string) => {
        const token = localStorage.getItem("accessToken")
        const fbclid = localStorage.getItem("fbclid")
        const subid = localStorage.getItem("subid")
        const email = localStorage.getItem("email")
        const clientId = localStorage.getItem("clientId")

        const data = {
            event: 'age_group_selected',
            data:{
                property: option,
            },
            fbclid: fbclid,
            subid: subid,
            clientId: clientId,
            email: email
        }
        await fetch(MIXPANEL_API, {method: "POST", headers: {
                Authorization: 'Bearer ' + token,
                "Content-Type": "application/json"
            }, body: JSON.stringify(data)})
    }
    return <ContentContainer>
        <h4>{t("login")}</h4>
        <p className={'ageCheckInfo font-bebas'}>{t("ageCheckScreen.ageCheckInfo")}</p>
        <div className={'ageGapContainer'}>
            {AGE_GAPS.map((gap)=>{
                return <div key={gap} className={'ageGapButton'}>
                    <NextRouteButton option={gap} route={nextRoute} onClick={() => trackMixpanel(gap)}
                                     buttonTheme={ButtonThemes.DARK} className={''} />
                </div>
            })}
        </div>
    </ContentContainer>
}

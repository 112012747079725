import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {WORKOUT_CHALLENGE} from "../../CourseItem/contants";
import {BONDING_ADVENTURES_COUPLES} from "../../GameItem/contants";
import {getImage} from "../../../../utils/firebase/storage";


const Bottle = () => {
    const { t } = useTranslation('app');
    const title = t("games2_bottle_days_title")
    const subtitle = t("games2_bottle_subtitle2")
    const subtitle1 = t("games2_bottle_subtitle3")
    const subtitle2 = t("games2_bottle_subtitle4")
    const article = t("games2_bottle_article1")
    const note = t("games2_bottle_note1")
    const list = t("games2_bottle_list1", { returnObjects: true })
    const list1 = t("games2_bottle_list2", { returnObjects: true })
    const list2 = t("games2_bottle_list3", { returnObjects: true })
    const list3 = t("games2_bottle_list4", { returnObjects: true })
    const titleMain = t("bonding_adventures_couples_game")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("bonding_adventures_couples") + 0]}
                        objectToSave={{
                            img: BONDING_ADVENTURES_COUPLES[0].img,
                            value: 'bonding_adventures_couples',
                            route: '/bottle',
                            index: 0
                        }}
                        uuid={"bonding_adventures_couples0"}
                        route={"/games/bonding-adventures-couples"}
            />
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {t("games2_bottle_subtitle1")}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {article}
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                <ul>
                    {list.map((i: string) => <li className="child-container__list mt-16 typography-14">{i}</li>)}
                </ul>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle1}
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-18 typography-bold">
                {subtitle2}
            </div>
            <div className="child-container__list">
                {list2.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__note">
                <div className="mt-16 typography-14">
                    {note}
                </div>
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[2]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("games2_bottle_article")}
            </div>
            <div className="child-container__list">
                {list3.map((i: string, idx: number) => <>
                    <div className="child-container__image mt-24">
                        <img loading="lazy" src={imgUrl[3+idx] || imgUrl[idx-10]} alt="course-preview" />
                    </div>
                    <div className="child-container__games-wrapper column">
                        <div className="icon">
                        <div className="icon__value child-container__red-title typography-14 typography-bold">
                            {idx + 1}
                        </div>
                        </div>
                    <div className="child-container__list mt-16 typography-14"> {i}</div>
                    </div>
                        </>)}
            </div>
        </div>
            </>
    )
}

export default Bottle
import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {TRUTH_OR_DARE_ADVANCED} from "../../contants";
export const TopPositions = () => {
    const { t } = useTranslation('app');
    const titleMain = t("truth_or_dare_advanced_premium").replaceAll('"', "")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("truth_or_dare_advanced") + 1]}
                        objectToSave={{
                            img: TRUTH_OR_DARE_ADVANCED[1].img,
                            value: 'truth_or_dare_advanced',
                            route: '/top-positions',
                            index: 1
                        }}
                        uuid={"truth_or_dare_advanced1"}
                        route={"/premium/truth-or-dare-advanced"}
            />
        <div className="child-container">
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("premium_truth2_subtitle")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("premium_truth2_article")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("premium_truth2_article_1")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("premium_truth2_subtitle1")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("premium_truth2_article1")}
            </div>

            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("premium_truth2_article1_2")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("premium_truth2_subtitle2")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("premium_truth2_article2")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("premium_truth2_article2_1")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[2]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("premium_truth2_subtitle3")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("premium_truth2_article3")}
            </div>
            <div className="child-container__article-title mt-16 typography-16">
                {t("premium_truth2_subtitle4")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[3]} alt="course-preview" />
            </div>

            <div className="child-container__article-title mt-16 typography-16">
                {t("premium_truth2_article4")}
            </div>
        </div>
            </>
            )
}
import {useTranslation} from "react-i18next";
import {ButtonThemes} from "../../../constants/button.constants";
import './EmailVerificationScreen.scss';
import {ContentContainer} from "../../../components/onboarding/ContentContainer/ContentContainer";
import {NextRouteButton} from "../../../components/NextRouteButton/NextRouteButton";
import {Footer} from "../../../components/lego/Footer/Footer";
import {PolicyAnchors} from "../../../constants/onBoardingConstants/policyAnchors.constants";
import { PaymentModal } from "components/onboarding/PayInfoContainer/PaymentModal";
import GiftModal from "../PayInfoScreen/GiftModal";
import NewDiscountModal from "../PayInfoScreen/NewDiscountModal";
import { TEN_MINUTES_TIMER_DURATION } from "../PayInfoScreen/PayInfoScreen";
import { Button } from "components/lego/Button/Button";
import { SIGN_UP_OFFER_ROUTE } from "constants/routes.constants";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {priceValues, pricePeriods} from "../../../constants/price";

export const EmailVerificationScreen =({nextRoute}:{nextRoute:string})=>{
    const { t } = useTranslation('onboarding');
    const navigate = useNavigate()
    const [open, setIsOpen] = useState(false)
    const [isGiftOpen, setIsGiftOpen] = useState(false)
    const [isNewPrice, setIsNewPrice] = useState(false)
    const [isNewDiscount, setIsNewDiscount] = useState(false)
    const [isClosedFirsTime, setIsClosedFirsTime] = useState(false)
    const [isTimerOver, setIsTimerOver] = useState(false)
    const [price, setPrice] = useState(88.99)
    useEffect(() => {
        setTimeout(() => {
            setIsTimerOver(true)
        }, TEN_MINUTES_TIMER_DURATION)
    },[])

    useEffect(() => {
        const localPrice = localStorage.getItem("price_plan")
        const localTimer = localStorage.getItem("isTimerOver")
        if(localPrice === '0' || localPrice === '1' || localPrice === '2') setPrice(priceValues[Number(localPrice)])
        if(localTimer === 'true') setIsTimerOver(true)
        else setIsTimerOver(false)
    }, []);
    useEffect(() => {
        if(isClosedFirsTime && !isGiftOpen){
            setIsGiftOpen(true)
        }
    }, [isClosedFirsTime])
    const getWithDiscount = () => {
        setIsNewDiscount(false)
        setIsNewPrice(true)
        setIsOpen(true)
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'get_my_plan_special-offer',
            event_value: `${(isTimerOver ? price : price * (isNewPrice ? 0.4 : 0.5)).toFixed(2)}`,
            discount:  isTimerOver ? 0 : isNewPrice ? 0.6 : 0.5,
            event_category: "subscription",
            event_label: `${pricePeriods[priceValues.indexOf(price)]}-week plan selected`

        });
    }

    const closeModal = () => {
        setIsOpen(false)
        navigate(SIGN_UP_OFFER_ROUTE)
    }
    return (
        <>
            <div>
            <ContentContainer>
                <h4 className={'emailVerificationTitle'}>{t('verifyEmailScreen.title')}</h4>
                <h6 className={'emailVerificationText'}>{t('verifyEmailScreen.text')}</h6>
            </ContentContainer>
                <Button text={t('verifyEmailScreen.continueButton')}
                        onClick={() => setIsOpen(true)}
                        buttonTheme={ButtonThemes.DARK}
                        width={100}
                        className={'EmailVerificationButton'} />
            </div>
            <Footer text={<span className="">
                    {t("privatePolicyText")}
                <a href={PolicyAnchors.TERMS}>{t("createAccountScreen.policyAnchors.terms")}</a>      &nbsp;
                <a href={PolicyAnchors.PRIVACY}>{t("createAccountScreen.policyAnchors.privacy")}</a>{' '}      &nbsp;
                <a href={PolicyAnchors.SUBSCRIPTION}>{t("createAccountScreen.policyAnchors.subscription")}</a>      &nbsp;
                </span>}/>
            <PaymentModal
                setIsClosedFirsTime={setIsClosedFirsTime}
                isClosedFirsTime={isClosedFirsTime}
                t={t}
                open={open}
                setOpen={setIsOpen}
                price={price}
                isNewPrice={isNewPrice}
                setIsNewPrice={setIsNewPrice}
                isTimerOver={isTimerOver} />
            <GiftModal
                t={t}
                closeModal={closeModal}
                setOpen={setIsGiftOpen}
                onSubmit={() => {
                    setIsOpen(false)
                    setIsNewDiscount(true)
                }}
                isOpen={isGiftOpen} />
            <NewDiscountModal
                t={t}
                timerDuration={TEN_MINUTES_TIMER_DURATION}
                isTimerOver={isTimerOver}
                onClick={() => setIsOpen(true)}
                setPrice={setPrice}
                isOpen={isNewDiscount}
                setOpen={setIsNewDiscount}
                onSubmit={getWithDiscount}
            />
        </>
    )
}
import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {AT_HOME} from "../../constants";


const GameWithStory = () => {
    const { t } = useTranslation('app');
    const list = t("scenarios1_story_fueled_list", { returnObjects: true })
    const list1 = t("scenarios1_story_fueled_list1", { returnObjects: true })
    const list2 = t("scenarios1_story_fueled_list2", { returnObjects: true })
    const titleMain = t("at_home_scenario")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("at_home") + 3]}
                        objectToSave={{
                            img: AT_HOME[3].img,
                            value: 'at_home',
                            route: '/game-with-story',
                            index: 3
                        }}
                        uuid={"at_home3"}
                        route={"/scenarios/at-home"}
            />
        <div className="child-container">
            <div className="child-container__list">
                {list.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("scenarios1_story_fueled_subtitle1")}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("scenarios1_story_fueled_article1")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list2.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
        </div>
            </>
    )
}

export default GameWithStory
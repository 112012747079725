import '../../FirstDateRule/styles.scss'
import Intro from "./Intro";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {PLEASURE_PATHWAYS} from "../../../CourseItem/contants";
const KissingTechniques = () =>{
    const { t } = useTranslation('app');
    const title = t("pleasure_pathways_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("path_to_pleasure") + 10]}
                        objectToSave={{
                            img: PLEASURE_PATHWAYS[10].img,
                            value: 'pleasure_pathways',
                            route: '/sensual-techniques',
                            index: 10
                        }}
                        uuid={"pleasure_pathways10"}
                        route={"/courses/pleasure-pathways"}
            />

               <Intro />
        </>
    )
}

export default KissingTechniques
import {useParams} from "react-router";
import {Route, Routes} from "react-router-dom";
import TabsFooter from "../../../../components/Bar";
import {COURSE_ROUTES} from "../../../../constants/routes.constants";
import {ApproachesRoutes} from "./contstants";
import Scheme from "./Scheme";
import Phrases from "./Phrases";
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {MASTERING_YOUR_MASCULINITY} from "../../CourseItem/contants";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {getAuth} from "firebase/auth";

const ApproachesToConnecting = () => {
    const {tab} = useParams()
    const { t } = useTranslation('app');
    const title = t("mastering_your_masculinity_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("MASTERING_YOUR_MASCULINITY") + 4]}
                        objectToSave={{
                            img: MASTERING_YOUR_MASCULINITY[4].img,
                            value: 'MASTERING_YOUR_MASCULINITY',
                            route: '/approaches-to-connect/1',
                            index: 4
                        }}
                        uuid={"MASTERING_YOUR_MASCULINITY4"}
                        route={"/courses/mastering-your-masculinity"}
            />
            <Routes>
                <Route path={'/1'} element={<Scheme />} />
                <Route path={'/2'} element={<Phrases />} />
            </Routes>

            <TabsFooter tab={tab || ''} routes={ApproachesRoutes} routeToNavigate={COURSE_ROUTES.APPROACHES_TO_CONNECT}  />
        </>
    )
}
export default ApproachesToConnecting
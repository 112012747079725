
export const API_HOST = 'https://mm-nest-704416274143.us-central1.run.app'
export const URL_COUNTRIES =API_HOST + '/payment/countries'
export const URL_STATE = (country_id: string) => API_HOST + `/payment/states/${country_id}`
export const URL_CITY = (state_id: string) => API_HOST + `/payment/cities/${state_id}`

export const PAYMENT_API_NEW = API_HOST + '/payment/card-request'
export const MIXPANEL_API = API_HOST + '/mixpanel/track'
export const CANCEL_PAYMENT_API = API_HOST + '/payment/cancel'
export const PAYMENT_PREMIUM_API = API_HOST + '/payment/card-request/premium'
export const SUBSCRIPTION_PERIOD_API = API_HOST + '/payment/subscription-end-date'

export const GET_SUBSCRIPTION = API_HOST + '/user/subscription'
export const GET_SUBSCRIPTION_PREMIUM = API_HOST + '/user/premium'
import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import {WORK} from "../../constants";


const PrettyWoman = () => {
    const { t } = useTranslation('app');
    const subtitle = t("scenarios4_seductive_subtitle")
    const list1 = t("scenarios4_seductive_list", { returnObjects: true })
    const list2 = t("scenarios4_seductive_list1", { returnObjects: true })
    const list3 = t("scenarios4_seductive_list2", { returnObjects: true })
    const titleMain = t("work_scenario")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("work") + 4]}
                        objectToSave={{
                            img: WORK[4].img,
                            value: 'work',
                            route: '/seductive-deal',
                            index: 4
                        }}
                        uuid={"work4"}
                        route={"/scenarios/work"}
            />
        <div className="child-container">
            <div className="child-container__red-title font-bebas typography-18 typography-bold">
                {subtitle}
            </div>
            <div className="child-container__list">
                {list1.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>

            <div className="child-container__list">
                {list2.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            <div className="child-container__list">
                {list3.map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>

            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[2]} alt="course-preview" />
            </div>

        </div>
            </>
    )
}

export default PrettyWoman
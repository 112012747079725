import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import { getAuth } from "firebase/auth";
import { useState, useEffect } from "react";
import { TFavoritesList } from "screens/app/FavoritesPage/FavoritesPage";
import { getListOfFavorites } from "utils/firebase/userData.utils";
import { AT_HOME } from "../../constants";


const Gentile = () => {
    const { t } = useTranslation('app');
    const titleMain = t("at_home_scenario")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("at_home") + 0]}
                        objectToSave={{
                            img: AT_HOME[0].img,
                            value: 'at_home',
                            route: '/gentile',
                            index: 0
                        }}
                        uuid={"at_home0"}
                        route={"/scenarios/at-home"}
            />
        <div className="child-container">
            <div className="child-container__article-title typography-16">
                {t("scenarios1_gentile_article")}
            </div>
            <div className="child-container__title font-bebas typography-20 mt-24 ">
                {t("scenarios1_gentile_title")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("scenarios1_gentile_subtitle")}
            </div>
            <div className="child-container__list">
                {t("scenarios1_gentile_list", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("scenarios1_gentile_subtitle1")}
            </div>
            <div className="child-container__list">
                {t("scenarios1_gentile_list1", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>

            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("scenarios1_gentile_subtitle2")}
            </div>
            <div className="child-container__list">
                {t("scenarios1_gentile_list2", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[2]} alt="course-preview" />
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("scenarios1_gentile_subtitle3")}
            </div>
            <div className="child-container__list">
                {t("scenarios1_gentile_list3", {returnObjects: true}).map((i: string) => <div className="child-container__list mt-16 typography-16">{i}</div>)}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[3]} alt="course-preview" />
            </div>
        </div>
            </>
    )
}

export default Gentile
import { createUserWithEmailAndPassword, updatePassword } from "firebase/auth";
import {auth, db} from "../../../firebase/firebase.config";
import {writeUserDataToDatabase, getUserData} from "../userData.utils";
import {ref, set} from "firebase/database";
import {API_HOST} from "../../../components/onboarding/PayInfoContainer/constants";

export const createUserAccount=(email:string, password:string,username:string,emailVerificationInfoScreen:(user: any)=>void, failed: () => void)=> {
    createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
            // await sendEmailVerification(userCredential.user)
            if(!userCredential.user?.accessToken) return
            await fetch(API_HOST + `/user/${username}`, {method: "POST", body:null,  credentials: 'include', headers: {Authorization: 'Bearer ' + userCredential.user?.accessToken, "Access-Control-Allow-Origin": "*"}})
                .finally(async() => {
                    await auth.signOut()
                    await writeUserDataToDatabase(userCredential.user.uid, username, email, "")
                    emailVerificationInfoScreen(userCredential.user)
                })

        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            if(errorCode === 'auth/email-already-in-use') failed()
        });
}

export const changePassword = (password:string, next: () => void) => {
    const user = auth.currentUser;
    if(!user) return
    updatePassword(user, password).then(() => {
        // Update successful.
        next()
    }).catch((error) => {
        const errorMessage = error.password;
    });
}

export const changeUsername = async(username:string, next: () => void) => {
    const user = auth.currentUser;
    if(!user) return
    const oldUserData = await getUserData(user.uid);
    if (!oldUserData) return
    const userRef = ref(db, "usersData/" + user.uid);
    await set(userRef, {
        ...oldUserData,
        username
    });
}


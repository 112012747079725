import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import {COURSES} from "../../Main/constants";
import { getImage } from "utils/firebase/storage";


const Sms = () => {
    const { t } = useTranslation('app');
    const title = t("scenarios6_list_title")
    const list = t("scenarios6_list", { returnObjects: true })
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("course") + 4]}
                        objectToSave={{
                            img: COURSES[4].img,
                            value: 'course_info',
                            route: '/sms',
                            index: 4
                        }}
                        uuid={"course_4"}
                        route={"/courses"}
            />
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            {list.map((i: string,idx:number) => {
                return <> <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                    {i}
                </div>
                    <div className="child-container__image mt-24">
                        <img loading="lazy" src={imgUrl[1+idx]} alt="course-preview" />
                    </div>
                </>
            })
            }
        </div>
            </>
    )
}

export default Sms
import '../FirstDateRule/styles.scss'
import {useTranslation} from "react-i18next";
import TabsHeader from "../../../../components/TabsHeader/TabsHeader";
import {MASTERING_YOUR_MASCULINITY} from "../../CourseItem/contants";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../utils/firebase/userData.utils";
import MaintainingEngaging from "../FirstDateRule/MaintainingEngaging";

const PathConnection = () => {
    const { t } = useTranslation('app');
    const title = t("mastering_your_masculinity_course")
    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={title}
                        isActive={!!listFav[("MASTERING_YOUR_MASCULINITY") + 6]}
                        objectToSave={{
                            img: MASTERING_YOUR_MASCULINITY[6].img,
                            value: 'MASTERING_YOUR_MASCULINITY',
                            route: '/maintaining',
                            index: 6
                        }}
                        uuid={"MASTERING_YOUR_MASCULINITY6"}
                        route={"/courses/mastering-your-masculinity"}
            />
         <MaintainingEngaging />

        </>
    )
}

export default PathConnection
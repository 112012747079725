import {useTranslation} from "react-i18next";
import {imgUrl} from "./constants";
import TabsHeader from "../../../../../components/TabsHeader/TabsHeader";
import {useEffect, useState} from "react";
import {TFavoritesList} from "../../../FavoritesPage/FavoritesPage";
import {getAuth} from "firebase/auth";
import {getListOfFavorites} from "../../../../../utils/firebase/userData.utils";
import { TRUTH_OR_DARE_ADVANCED} from "../../contants";

export const BlowJobPremium = () => {
    const { t } = useTranslation('app');
    const titleMain = t("truth_or_dare_advanced_premium").replaceAll('"', "")
    const title = t("premium_truth1_subtitle")


    const [listFav, setList] = useState<TFavoritesList>({})
    const user = getAuth()
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])
    return(
        <>
            <TabsHeader title={titleMain}
                        isActive={!!listFav[("truth_or_dare_advanced") + 0]}
                        objectToSave={{
                            img: TRUTH_OR_DARE_ADVANCED[0].img,
                            value: 'truth_or_dare_advanced',
                            route: '/unforgettable-cunnilingus-scenarios',
                            index: 0
                        }}
                        uuid={"truth_or_dare_advanced0"}
                        route={"/premium/truth-or-dare-advanced"}
            />
        <div className="child-container">
            <div className="child-container__title font-bebas typography-20">
                {title}
            </div>
            {t("premium_truth1_list", {returnObjects: true}).map((i: string) => {
                return <>
                    <div className="child-container__article-title mt-24 typography-16">
                        {i}
                    </div>
                </>
            })
            }
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[0]} alt="course-preview" />
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("premium_truth1_subtitle1")}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("premium_truth1_article")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("premium_truth1_subtitle2")}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("premium_truth1_article2")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("premium_truth1_subtitle3")}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("premium_truth1_article3")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("premium_truth1_subtitle4")}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("premium_truth1_article4")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[1]} alt="course-preview" />
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("premium_truth1_article4_1")}
            </div>
            <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
                {t("premium_truth1_subtitle5")}
            </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("premium_truth1_article5")}
            </div>   <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
            {t("premium_truth1_subtitle6")}
        </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("premium_truth1_article6")}
            </div>   <div className="child-container__red-title font-bebas mt-24 typography-18 typography-bold">
            {t("premium_truth1_subtitle7")}
        </div>
            <div className="child-container__article-title mt-24 typography-16">
                {t("premium_truth1_article7")}
            </div>
            <div className="child-container__image mt-24">
                <img loading="lazy" src={imgUrl[2]} alt="course-preview" />
            </div>
        </div>
            </>
            )
}